
export default {
  name: "ListDiscussion",
  components: {
    CommunityQuestionMember: () =>
      import("@/components/CommunityQuestionMember"),
    CommunityPinned: () => import("@/components/CommunityPinned"),
    questionAvatar: () =>
      import("@/components/ask-question/questionAvatar.vue"),
    photoGallery: () =>
      import("@modoc-component/src.components.photo-gallery/photo-gallery.vue"),
    questionGallery: () => import("@/components/community/gallery.vue"),
    DiscussionInteraction: () =>
      import("@/components/molecules/DiscussionInteraction.vue"),
    // questionGallery: () =>
    //   import("@modoc/modoc-mf.components.gallery/gallery.vue"),
  },
  props: {
    mode: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: () => [],
    },
    member: {
      type: Object,
      default: () => {},
    },
    pins: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    loadingScroll: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    showScroll: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    isOwned: {
      type: Boolean,
      default: false,
    },
    nilai: {
      type: Number,
      default: 0,
    },
  },
  data: () => {
    return {
      postIDBroadcastCandidate: null,
      isBroadcastDialogShow: false,
      openCollapse: "open",
      dataLength: 5,
      page: 1,
      active_report: false,
      report_item: null,
      report_id: null,
      report_questions: [
        {
          text: "Gambar",
          id: 1,
        },
        {
          text: "Spam",
          id: 2,
        },
        {
          text: "Offensive",
          id: 3,
        },
        {
          text: "Abuse",
          id: 4,
        },
        {
          text: "SARA",
          id: 5,
        },
      ],
      report_others_answer: "",
    };
  },
  computed: {
    noMore() {
      return this.list.length < this.total;
    },
    disabled() {
      return this.pins.length + this.list.length >= this.total;
    },
  },
  methods: {
    objectArrayParser(array) {
      return array.map((el) => {
        return el.url;
      });
    },
    load() {
      this.$emit("scroll");
    },
    loadMore() {
      this.$emit("more");
    },
    refetch() {
      this.$emit("refetch");
    },
    handleDropdown(val, item) {
      switch (val) {
        case "broadcast":
          this.handleBroadcast(item);
          break;
        case "remove":
          this.deleteQuestion(item);
          break;
        case "pin":
          this.pinQuestion(item);
          break;
        case "report":
          this.reportQuestion(item);
          break;
      }
    },
    handleBroadcast(item) {
      this.isBroadcastDialogShow = true;
      this.postIDBroadcastCandidate = item.id;
    },
    async sendBroadcast() {
      this.$axios
        .post(process.env.BASE_API + "communities/questions/broadcast", {
          community_content_id: this.postIDBroadcastCandidate,
        })
        .then((response) => {
          if (response.status) {
            this.$notify.success({
              message: "Berhasil broadcast diskusi",
              offset: 100,
              duration: 3500,
            });
            this.isBroadcastDialogShow = false;
            this.postIDBroadcastCandidate = null;
          }
        })
        .catch((err) => {
          this.$notify.error({
            message: "gagal broadcast diskusi",
            offset: 100,
            duration: 3500,
          });
          this.isBroadcastDialogShow = false;
          this.postIDBroadcastCandidate = null;
        });
    },
    handleMemberDropdown(val, id) {
      this.$emit("status-member", val, id);
    },
    reportQuestion(val) {
      this.report_id = val.id;
      this.active_report = true;
    },
    moreMember(val) {
      this.$emit("moreMember", val);
    },
    async pinQuestion(item) {
      const status = item.is_pinned === 1 ? 0 : 1;
      const id = item.id;
      const formData = new FormData();
      formData.append("is_pinned", status);
      const data = {
        id,
        formData,
      };
      await this.$store
        .dispatch("question/pinnQuestion", data)
        .then((res) => {
          this.$message({
            message: "Berhasil sematkan diskusi",
            type: "success",
          });
          this.refetch();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            message: "Gagal sematkan diskusi",
            type: "error",
          });
        });
    },
    async deleteQuestion(item) {
      await this.$store
        .dispatch("question/deleteQuestion", item.id)
        .then((res) => {
          this.$message({
            message: "Berhasil hapus diskusi",
            type: "success",
          });
          this.refetch();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            message: "Gagal hapus diskusi",
            type: "error",
          });
        });
    },
    async sendReport() {
      if (!this.$store.state.auth.user.isLogin) {
        this.$goTo("login");
      } else {
        const formData = new FormData();
        formData.append("reported_id", this.report_id);
        formData.append("reason_id", this.report_item);
        await this.$store
          .dispatch("public/postReportQuestion", formData)
          .then((res) => {
            if (res.status) {
              this.$notify.success({
                message: "Berhasil melaporkan pertanyaan",
                offset: 100,
                duration: 3500,
              });
            } else {
              this.$notify.error({
                title: "Gagal melaporkan pertanyaan",
                offset: 100,
                message:
                  "Terjadi kesalahan server, silahkan coba beberapa saat lagi",
              });
            }
            this.active_report = false;
          })
          .catch(() => {
            this.active_report = false;
            this.$notify.error({
              title: "Gagal melaporkan pertanyaan",
              offset: 100,
              message:
                "Terjadi kesalahan server, silahkan coba beberapa saat lagi",
            });
          });
      }
    },
    async submitLike(item) {
      if (!this.$store.state.auth.user.isLogin) {
        this.$goTo("login");
      } else {
        const payload = {
          id: item.id,
          module: "question",
          status: 0,
        };
        await this.$store
          .dispatch("public/postLikeQuestion", payload)
          .then((res) => {
            if (res.status) {
              if (res.data && res.data.likes_count) {
                this.list.map((obj, index) => {
                  if (obj.id === item.id) {
                    this.list[index].total_like = res.data.likes_count;
                  }
                });
              }
              this.$notify.success({
                message: "Berhasil menambah like",
                offset: 100,
                duration: 3500,
              });
            } else {
              this.$notify.error({
                title: "Gagal menambah like",
                offset: 100,
                message:
                  "Terjadi kesalahan server, silahkan coba beberapa saat lagi",
              });
            }
          })
          .catch(() => {
            this.$notify.error({
              title: "Gagal menambah like",
              offset: 100,
              message:
                "Terjadi kesalahan server, silahkan coba beberapa saat lagi",
            });
          });
      }
    },
    handleShare(val, item) {
      if (!window) {
        return;
      }
      const base = window.location.host;
      const dateNow = new Date().getTime();
      const username = this.$store.state.auth.user.myProfile.slug
        ? this.$store.state.auth.user.myProfile.slug
        : "_" + Math.floor(Math.random() * 10000 + 1);
      const url = `https://${base}/mb/community/${item.id_community}/${item.id}?slug=${username}&_utm=${dateNow}`;
      switch (val) {
        case "fb":
          return window.open(
            "http://www.facebook.com/sharer/sharer.php?u=" +
              url +
              "&_ch=facebook",
            "_blank"
          );
        case "wa":
          return window.open(
            "https://api.whatsapp.com/send?text=" + url + "&_ch=whatsapp",
            "_blank"
          );
        case "tw":
          return window.open(
            "https://twitter.com/share?url=" + url + "&_ch=twitter",
            "_blank"
          );
        case "in":
          return window.open(
            "http://www.linkedin.com/shareArticle?mini=true&url=" +
              url +
              "&_ch=linkedin",
            "_blank"
          );
      }
    },
  },
};
