
export default {
  components: {
    questionAvatar: () => import('@/components/ask-question/questionAvatar.vue')
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    isOwned: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    },
    member: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      dataLength: 5,
      page: 1
    }
  },
  methods: {
    handleMemberDropdown (val, id) {
      this.$emit('status-member', val, id)
    },
    moreMember () {
      this.dataLength += 5
      this.page += 1
      this.$emit('moreMember', this.page)
    }
  }

}
